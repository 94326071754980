
import { defineComponent, ref, computed } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import KTNotificationMenu from "@/layout/header/partials/Notifications.vue";

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
    KTNotificationMenu,
  },
  setup() {
    const notification_count = ref(0);
    const audio_player = ref();
    const unread = computed(() => {
      if (notification_count.value > 99) {
        return "99+";
      }
      return notification_count.value;
    });

    function notifCountChange(count) {
      notification_count.value = count;
    }
    function playNotif() {
      if (audio_player.value) {
        audio_player.value!.play();
      }
    }
    return {
      audio_player,
      notification_count,
      unread,
      notifCountChange,
      playNotif,
    };
  },
});
