
import { defineComponent } from "vue";
import { useStore } from "vuex";
import KTTopbar from "@/layout/header/Topbar.vue";
// import KTMenu from "@/layout/header/Menu.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    // KTMenu,
  },
  setup() {
    const store = useStore();
    let app_name = `<span class="text-primary">SIPUTRA</span> - SISTEM INPUT BERITA`;
    const projectName = store.getters.currentUser.project
      ? store.getters.currentUser.project.name
      : app_name;

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      projectName,
    };
  },
});
